import React, { useState, useEffect } from "react";
import "./swiper.css";
import { useRef } from "react";

const Swiper = ({ children }) => {
  const [num, setNum] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let isGoingRight = true;

    const scroll = () => {
      if (container) {
        const { scrollLeft, scrollWidth, clientWidth } = container;
        if (isGoingRight && scrollLeft + clientWidth < scrollWidth) {
          container.scrollTo({
            left: scrollLeft + clientWidth,
            behavior: "smooth",
          });
        } else {
          container.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };

    const interval = setInterval(scroll, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="swipeable-div-container" ref={containerRef}>
      <img
        src="../img/baycnft.png"
        className="swipeable-div"
        style={{ height: "380px" }}
      />
    </div>
  );
};

export default Swiper;
