import React from "react";
import classes from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div className={classes.sections_border}></div>
      <div className={classes.footer_data}>
        <div>
          <a href="https://sandbox.game" target="_blank" rel="noopener noreferrer">
            <img src="../img/TheSandboxFooterLogo.png" />
          </a>
          <h3 style={{ color: "#fff" }}>FOLLOW US</h3>
          <div>
            <a href="#">
              <img src="../img/discord-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/telegram-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/discord-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/twitter-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/instagram-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/facebook-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/youtube-white.png" alt="" />
            </a>
            <a href="#">
              <img src="../img/twich-white.png" alt="" />
            </a>
          </div>
          <p style={{ color: "#9D9FA1FF" }}>
            Copyright 2012-2024 The Sandbox. All Rights Reserved
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className={classes.footer_info}>
            <h2>Participating in the Giveaway:</h2>
            <h3>
              To be eligible for the prize draw, participants must own any NFT
              from the Mutant Ape Yacht Club or Bored Ape Yacht Club
              collections, or possess digital real estate within The Sandbox
              metaverse. Once you meet these requirements, all you need to do is
              visit the dedicated giveaway website, connect your wallet, and
              await the results. The more qualifying NFTs you hold, the higher
              your chances of winning
            </h3>
            <p style={{ color: "#405B6A", fontWeight: "bold" }}>
              ENDS: 24TH APR
            </p>
          </div>
          <img
            src="../img/sandlogo1.png"
            width={"100%"}
            className={classes.footer_logo}
          />
        </div>
        <div>
          <div style={{ textAlign: "center" }}>
            <a href="https://boredapeyachtclub.com" target="_blank">
              <img src="../img/footer_ape.png" height={"120px"} />
            </a>
          </div>
          <div className={classes.social_links}>
            <a href="#">
              <p>Discord</p>
            </a>
            <a href="#">
              <p>X(twitter)</p>
            </a>
            <a href="#">
              <p>Youtube</p>
            </a>
            <a href="#">
              <p>Instagram</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
