import Footer from "./components/footer/Footer";
import ImageSlider from "./components/image-slider/ImageSlider";
import Swiper from "./components/swiper/Swiper";
import { useRef } from "react";
import { useEffect } from "react";

function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      video.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    };

    video.addEventListener("canplaythrough", playVideo);

    return () => {
      video.removeEventListener("canplaythrough", playVideo);
    };
  }, []);

  return (
    <>
      <section className="video-section">
        <div class="dark-overlay"></div>
        <video autoPlay muted loop ref={videoRef}>
          <source src="./video/vid.mp4" type="video/mp4"></source>
        </video>
        <div className="section-data">
          <img src="./img/sandlogo1.png" className="sand-logo" />
          <h1 className="p_1">
            The Sandbox, a leading decentralized gaming virtual world, is
            thrilled to announce an exciting collaboration with the renowned
            Bored Cape yacht club
          </h1>
          <h2 className="p_2">
            Together we are bringing you a grand giveaway.where participants
            stand ar chance to win incredible orzesimciuding 2 000 00 worth or
            sS AND tokens 20 LAND parcels oN hs from the Bored ape Yadhit
            qub.collection. andlu0NFs ffomthelMutamt Ape YachaGubicollecton
          </h2>
          <img src="./img/connectwallet.png" className="connect-btn" />
          <img src="./img/bored_ape.png" className="bored_ape" />
        </div>
      </section>
      <section className="introduction-section">
        <div className="sections-border"></div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="section_intro_data">
            <div className="block_1">
              <h1>Introducing the latest NFT collection from The Sandbox:</h1>
              <span>
                Aero LAND's! These unique digital assets will be available
                during the pre-release phase, with 10 exclusive NFTs up for
                grabs as part of the collaboration between The Sandbox and Bored
                Ape Yacht Club.
              </span>
              <span>
                AeroLAND's combine the immersive world of The Sandbox with the
                iconic artwork of Bored Ape Yacht Club, creating a one-of-a-kind
                digital experience for collectors and fans alike. These NFTs
                offer a glimpse into a futuristic landscape, blending
                cutting-edge technology with artistic creativity.
              </span>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <button>Enter Raffle</button>
                <p>Learn more about AeroLAND's</p>
              </div>
            </div>
            <ImageSlider
              images={[
                "../img/AeroLAND1.png",
                "../img/AeroLAND2.png",
                "../img/AeroLAND3.png",
              ]}
            />
          </div>
          <h1 style={{ textAlign: "center", color: "#fff" }}>
            Become the owner of one of these NFTs:
          </h1>
          <Swiper />
        </div>
        <div className="sections-border bottom-border"></div>
      </section>
      <section className="yaght-section">
        <div className="sections-border"></div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="section_yaght_data">
            <div className="yaght_data_info">
              <img src="./img/BAYClogo.png" />
              <h2>About Bored Ape Yacht Club and Mutant Ape Yacht Club:</h2>
              <h2>
                Bored Ape Yacht Club and Mutant Ape Yacht Club are highly
                sought-after NFT collections, known for their exquisite artistry
                and limited availability. These collections have captured the
                attention of collectors and enthusiasts worldwide, elevating the
                concept of digital ownership to new heights.
              </h2>
              <h2>
                The Sandbox, with its vibrant and immersive metaverse, offers
                the perfect platform for showcasing these iconic Nfs. By
                integrating Bored Ape Yacht Club and Mutant Ape Yacht Club
                avatars into The Sandbox, users will have the opportunity to
                express their individuality and explore endless possibilities
                within the virtual realm.
              </h2>
              <img className="connect-btn" src="./img/connectwallet.png" style={{ width: "250px" }} />
            </div>
            <img
              src="./img/Frame1.png"
              width={"100%"}
              style={{ maxHeight: "645px", maxWidth: "683px" }}
            />
          </div>
        </div>
        <div className="sections-border bottom-border"></div>
      </section>
      <Footer />
    </>
  );
}

export default App;
